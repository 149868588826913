
import { computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import Summary from '@/components/subscriptions/add/Summary.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Company } from '@/store/modules/CompanyModule'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'edit-company',
  components: {
    Summary,
    Datepicker
  },
  setup () {
    onMounted(() => {
      setCurrentPageBreadcrumbs('Edit Company', ['Companies'])
    })
    const store = useStore()
    const router = useRouter()
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref<boolean>(false)
    const uuid = router.currentRoute.value.params.id

    store.dispatch(MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID, uuid)

    const company = ref(computed(() => store.getters.getCompany)) as unknown as Company
    const trialDays = ref(14) as any
    const trialDates = ref([new Date(), new Date().setDate((new Date()).getDate() + trialDays.value)]) as any

    watch(company, (newValue: any, oldValue) => {
      if (newValue.settings && newValue.settings.allowedTrial && newValue.settings.trialStart) {
        unref(company).settings.trialRange = newValue.settings.trialStart + '-' + newValue.settings.trialEnd
        const dateStart = new Date(newValue.settings.trialStart) as any
        const dateEnd = new Date(newValue.settings.trialEnd) as any
        const diffTime = Math.abs(dateEnd - dateStart)
        trialDays.value = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        trialDates.value = [new Date(newValue.settings.trialStart), new Date(newValue.settings.trialEnd)]
      } else if (newValue.settings && !newValue.settings.allowedTrial) {
        unref(company).settings.trialRange = trialDates.value[0] + '-' + trialDates.value[1]
      }
    })

    const rules = ref({
      label: [
        {
          required: true,
          message: 'Please input Company name',
          trigger: 'blur'
        }
      ],
      'account.firstName': [
        {
          required: true,
          message: 'Please input Master account first name',
          trigger: 'blur'
        }
      ],
      'account.lastName': [
        {
          required: true,
          message: 'Please input Master account last name',
          trigger: 'blur'
        }
      ],
      'account.phone': [
        {
          required: false,
          message: 'Please input the Phone number',
          trigger: 'blur'
        }
      ],
      'account.email': [
        {
          required: true,
          message: 'Please input the Email address',
          trigger: 'blur'
        }
      ],
      'account.type': [
        {
          required: true,
          message: 'Please select the account type',
          trigger: 'blur'
        }
      ],
      'country.code': [
        {
          required: true,
          message: 'Please select the country',
          trigger: 'blur'
        }
      ],
      'settings.trialRange': [
        {
          required: true,
          message: 'Please select the date range',
          trigger: 'change'
        }
      ]
    })
    // const uuid = router.currentRoute.value.params.id
    // onBeforeMount(() => {
    //   store.dispatch(MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID, uuid)
    // })
    const handleDaysChange = (currentNumber, oldNumber) => {
      if (typeof trialDates.value !== 'undefined' && trialDates.value !== null && typeof (trialDates.value[1]) !== 'undefined') {
        const date = new Date(trialDates.value[1])
        if (currentNumber > oldNumber) {
          date.setDate(date.getDate() + (currentNumber - oldNumber))
        } else {
          date.setDate(date.getDate() - (oldNumber - currentNumber))
        }
        trialDates.value[1] = date.toUTCString()
      }
    }
    const onRangeChanges = (modelData) => {
      console.log('trailDates', trialDates)
      unref(company).settings.trialRange = modelData
    }
    const submit = async (values) => {
      console.log(unref(company).settings.trialRange)
      if (!formRef.value) {
        return
      }
      let formValidation = false
      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation = false
          return false
        }
        loading.value = true
        formValidation = true
      })
      if (!formValidation) {
        await store.commit(Mutations.SET_COMPANY_ERRORS, {})

        Swal.fire({
          text: 'Sorry, looks like there are some errors detected, please try again.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        loading.value = false

        return
      }

      const payload = {
        label: unref(company).label,
        firstName: unref(company).account.firstName,
        lastName: unref(company).account.lastName,
        email: unref(company).account.email,
        phone: unref(company).account.phone,
        countryCode: unref(company).country.code,
        accountUuid: unref(company).account.accountUuid,
        settings: {
          allowDeploy: unref(company).settings.allowedToDeploy,
          allowPublish: unref(company).settings.allowedToPublish,
          allowTrial: unref(company).settings.allowedTrial
        }
      } as any

      if (payload.settings.allowTrial) {
        payload.settings.trialStart = (new Date(trialDates.value[0]).toISOString())
        payload.settings.trialEnd = (new Date(trialDates.value[1])).toISOString()
      }

      console.log('payload', payload)
      const uuid = router.currentRoute.value.params.id
      const companyPayload = { companyUuid: uuid, companyDetails: payload }
      store.dispatch(Actions.API_UPDATE_COMPANY, companyPayload)
        .then(() => {
          Swal.fire({
            text: 'You have successfully updated the company ' + payload.label,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          }).then(function () {
            loading.value = false
            router.push({ name: 'companies' })
          })
        }).catch(() => {
          loading.value = false
        })
    }

    return {
      submit,
      handleDaysChange,
      onRangeChanges,
      company,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      trialDays,
      trialDates
    }
  }
})
